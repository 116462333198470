import { JSONCodeBox, JSONCodeBoxProps, Stack, Text } from '@guider-global/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { IntegrationFormFields } from '../IntegrationView';

export interface IntegrationMappingsProps
  extends Pick<JSONCodeBoxProps, 'schema'> {
  id: string | undefined;
}

export function IntegrationMappings({ schema, id }: IntegrationMappingsProps) {
  const { control, setError } = useFormContext<IntegrationFormFields>();

  if (!id) return null;

  return (
    <Stack direction={'column'} spacing={2}>
      <Controller
        name={'mappings'}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <Text
                text="Mappings"
                variant="h6"
                component="p"
                color="inherit"
              />
              <JSONCodeBox
                id={id}
                schema={schema}
                value={ParseMapping(value)}
                onChange={({ content }) => {
                  const value = formatMapping(content, () => {
                    setError('mappings', {
                      message: 'Error formatting mappings',
                      type: 'manual',
                    });
                  });
                  onChange(value);
                }}
                onErrors={({ errors }) => {
                  setError('mappings', {
                    message: errors?.at(0)?.message,
                    type: 'manual',
                  });
                }}
                sx={{
                  height: '200px',
                  borderRadius: '8px',
                  borderWidth: '1px',
                  borderColor: 'grey.500',
                  opacity: 0.5,
                  '&:focus-within': {
                    borderColor: 'primary.main',
                    opacity: 1,
                  },
                  borderStyle: 'solid',
                  '& .monaco-editor': {
                    borderRadius: '8px',
                  },
                  '& .overflow-guard': {
                    borderRadius: '8px',
                  },
                }}
              />
            </>
          );
        }}
      />
    </Stack>
  );
}

export const ParseMapping = (mapping?: Record<string, any>) => {
  if (!mapping) return '';
  return JSON.stringify(mapping, undefined, 4);
};

export const formatMapping = (
  jsonString?: string,
  onError?: (props: {
    show: boolean;
    message: string;
    variant: 'error';
  }) => void,
): Record<string, any> | undefined => {
  try {
    if (!jsonString) return undefined;
    return JSON.parse(jsonString);
  } catch {
    if (onError) {
      onError({
        show: true,
        message: 'Error formatting mappings',
        variant: 'error',
      });
    }
    return undefined;
  }
};
