import { KeyStatItem } from '@guider-global/ui';

export interface IGetGroupKeyStatsParams {
  usersCount: number;
  traineesNotInGroupCount: number;
  organizationMemberCount: number;
  sessionsCount: number;
  organizationSessionCount: number;
  concludedGroupsCount: number;
  openGroupsCount: number;
  activeGroupsCount: number;
  traineeLabelPluralized: string;
}

export function getGroupKeyStats({
  usersCount,
  traineesNotInGroupCount,
  organizationMemberCount,
  sessionsCount,
  organizationSessionCount,
  concludedGroupsCount,
  openGroupsCount,
  activeGroupsCount,
  traineeLabelPluralized,
}: IGetGroupKeyStatsParams) {
  const keyStats: KeyStatItem[] = [
    {
      label: 'Program members',
      toolTipMessage: 'The total number of members enrolled in this program.',
      value: usersCount,
      key: 'users',
      percentageNumber: usersCount / organizationMemberCount,
      description: `${Math.round(
        (usersCount / organizationMemberCount) * 100,
      )}% of total organization users`,
    },
    {
      label: 'Sessions booked/logged',
      toolTipMessage:
        'The total number of sessions booked/logged in this program.',
      value: sessionsCount,
      key: 'sessions',
      percentageNumber: sessionsCount / organizationSessionCount,
      description: `${Math.round(
        (sessionsCount / organizationSessionCount) * 100,
      )}% of total organization sessions`,
    },
    {
      label: 'Concluded groups',
      toolTipMessage: 'Concluded groups for this program',
      value: concludedGroupsCount,
      key: 'concluded',
      description: 'concluded groups',
    },
    {
      label: 'Open groups',
      toolTipMessage: 'Groups waiting to start',
      value: openGroupsCount,
      key: 'open',
      description: 'open groups',
    },
    {
      label: 'Active groups',
      toolTipMessage: 'Groups in progress',
      value: activeGroupsCount,
      key: 'active',
      description: 'active groups',
    },
    {
      label: `${traineeLabelPluralized} without a group`,
      toolTipMessage: `${traineeLabelPluralized} that haven't joined a group`,
      value: traineesNotInGroupCount,
      key: 'withoutGroup',
      description: 'without groups',
    },
  ];

  return keyStats;
}
