import { IRestState, PickByType } from '@guider-global/redux-axios-hooks';
import {
  sanityBaseLanguageSlice,
  sanityLocalizationSlice,
  sanityOrganizationProgramsSlice,
  sanityOrganizationSlice,
  sanityProgramSlice,
} from '@guider-global/sanity-hooks';
import {
  IEvent,
  IMatch,
  IMetric,
  IMetricToken,
  IOrganization,
  IProfile,
  IProgramDetails,
  IProgramProfile,
  IRelationship,
  ISetting,
  IUser,
  IUserProfile,
  OrganizationIntegration,
} from '@guider-global/shared-types';
import { Middleware, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  ISnackbar,
  adminDeleteUserSlice,
  adminKPIsOrganizationSlice,
  adminKPIsProgramSlice,
  adminManualMatchSlice,
  adminMatchesSlice,
  adminMatchesV2Slice,
  adminMembershipsSlice,
  adminProgramsSlice,
  adminRelationshipsSlice,
  adminUserProfilesSlice,
  eventsSlice,
  metricsSlice,
  metricsTokensSlice,
  organizationIntegrationsSlice,
  organizationsSlice,
  profilesSlice,
  settingsSlice,
  snackbarSlice,
  testSlice,
  usersSlice,
} from './slices';
import { adminProgramProfilesSlice } from './slices/adminProgramProfilesSlice';

const rootReducer = {
  sanityOrganization: sanityOrganizationSlice.reducer,
  sanityProgram: sanityProgramSlice.reducer,
  adminMatches: adminMatchesSlice.reducer,
  adminMatchesV2: adminMatchesV2Slice.reducer,
  adminMemberships: adminMembershipsSlice.reducer,
  adminKPIsOrganization: adminKPIsOrganizationSlice.reducer,
  adminKPIsProgram: adminKPIsProgramSlice.reducer,
  adminManualMatch: adminManualMatchSlice.reducer,
  adminRelationships: adminRelationshipsSlice.reducer,
  adminUserProfiles: adminUserProfilesSlice.reducer,
  adminProgramProfiles: adminProgramProfilesSlice.reducer,
  adminPrograms: adminProgramsSlice.reducer,
  adminDeleteUser: adminDeleteUserSlice.reducer,
  users: usersSlice.reducer,
  test: testSlice.reducer,
  organizations: organizationsSlice.reducer,
  metrics: metricsSlice.reducer,
  metricsTokens: metricsTokensSlice.reducer,
  settings: settingsSlice.reducer,
  profiles: profilesSlice.reducer,
  sanityLocalization: sanityLocalizationSlice.reducer,
  sanityBaseLanguage: sanityBaseLanguageSlice.reducer,
  sanityOrganizationPrograms: sanityOrganizationProgramsSlice.reducer,
  events: eventsSlice.reducer,
  organizationIntegrations: organizationIntegrationsSlice.reducer,
  snackbarSlice: snackbarSlice.reducer,
};

const middleware: Middleware[] = [];

export const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
});

/** Core Redux Types */
export type StoreStateGetter = typeof store.getState;
export type RootState = ReturnType<StoreStateGetter>;
export type AppDispatch = typeof store.dispatch;
export type DispatchFunc = () => AppDispatch;

/** Typed dispatches & selectors */
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/** The redux state that defines which reducers are generated Rest reducers */
export type RestRootState = Pick<
  ReturnType<StoreStateGetter>,
  | 'organizations'
  | 'users'
  | 'adminUserProfiles'
  | 'adminMatches'
  | 'adminMatchesV2'
  | 'adminMemberships'
  | 'adminKPIsOrganization'
  | 'adminKPIsProgram'
  | 'adminManualMatch'
  | 'adminRelationships'
  | 'adminProgramProfiles'
  | 'adminPrograms'
  | 'adminDeleteUser'
  | 'metrics'
  | 'metricsTokens'
  | 'settings'
  | 'profiles'
  | 'events'
  | 'organizationIntegrations'
>;
/** The types of generated Rest Reducers */
export type RestRootStateTypes =
  | IOrganization
  | IUser
  | IUserProfile
  | IProgramProfile
  | IProgramDetails
  | IMatch
  | IRelationship
  | IMetric
  | IMetricToken
  | ISetting
  | IProfile
  | IEvent
  | OrganizationIntegration;
/** A selector for generated Rest Reducers */
export const useRestAppSelector: TypedUseSelectorHook<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PickByType<RootState, IRestState<any>>
> = useSelector;

export default store;
