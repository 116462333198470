import { AxiosError } from '@guider-global/redux-axios-hooks';
import {
  IntegrationFeatureType,
  OrganizationIntegration,
} from '@guider-global/shared-types';
import { AxiosPut, useGuiderSWRMutation } from '@guider-global/swr';

type MergeLinkRequests = AxiosPut<
  OrganizationIntegration,
  Partial<OrganizationIntegration>,
  {
    integrationName: OrganizationIntegration['integrationName'];
    publicToken: string;
  }
>;

export interface CreateMergeOrganizationIntegrationParams {
  integrationIdentifier: string | undefined;
  integrationName: OrganizationIntegration['integrationName'] | undefined;
  publicToken: string | undefined;
  features: IntegrationFeatureType[] | undefined;
  mappings: Record<string, string> | undefined;
}

export interface useMergeOrganizationIntegrationReturn {
  organizationIntegration: OrganizationIntegration | undefined;
  createMergeOrganizationIntegration: (
    params: CreateMergeOrganizationIntegrationParams,
  ) => Promise<any>;
  error: AxiosError | undefined;
  isLoadingOrganizationIntegration: boolean;
  reset: () => void;
}

export function useMergeOrganizationIntegration(): useMergeOrganizationIntegrationReturn {
  const { response, trigger, error, isMutating, reset } =
    useGuiderSWRMutation<MergeLinkRequests>({
      url: `/admin/organizationIntegrations/linkToken`,
    });

  const createMergeOrganizationIntegration = async ({
    integrationIdentifier,
    integrationName,
    publicToken,
    features,
    mappings,
  }: CreateMergeOrganizationIntegrationParams) => {
    if (!integrationIdentifier || !integrationName || !publicToken) {
      return null;
    }
    return trigger({
      url: `/admin/organizationIntegrations/merge/${integrationIdentifier}`,
      method: 'PUT',
      params: {
        integrationName,
        publicToken,
      },
      data: {
        integrationName,
        features,
        mappings,
      },
    });
  };

  return {
    organizationIntegration: response?.at(0),
    createMergeOrganizationIntegration,
    error,
    isLoadingOrganizationIntegration: isMutating,
    reset,
  };
}
