import {
  useBaseLanguage,
  useOrganization,
  useOrganizationIntegrations as useSanityOrganizationIntegrations,
} from '@guider-global/sanity-hooks';
import {
  IntegrationFeatureType,
  OrganizationIntegrationName,
} from '@guider-global/shared-types';
import {
  ButtonProps,
  ConfirmationModal,
  NoticeSnackbarProps,
} from '@guider-global/ui';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { ValidationErrors } from '@mergeapi/react-merge-link/dist/types';
import {
  getConnectedOrganizationIntegration,
  IntegrationFormFeatureField,
  IntegrationFormFields,
  IntegrationView,
  SelectedSanityIntegration,
} from 'components/Integrations';
import { useAppContext } from 'context';
import {
  useMergeLinkToken,
  useMergeOrganizationIntegration,
  useOrganizationIntegrations,
} from 'hooks';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export interface IntegrationsContainerProps {
  onError: (error: NoticeSnackbarProps) => void;
}

export function IntegrationsContainer({ onError }: IntegrationsContainerProps) {
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] =
    useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] =
    useState<Partial<SelectedSanityIntegration>>();

  const { organizationSlug } = useAppContext();
  const { organization } = useOrganization({
    organizationSlug: organizationSlug ?? 'guider',
  });
  const enabledOrganizations = organization?.enabled_integrations;
  const { baseLanguage } = useBaseLanguage({ localeCode: 'en_GB' });
  const discard_unsaved_changes_modal =
    baseLanguage?.relationships?.notes?.notes_modal
      ?.notes_modal_create_edit_note?.discard_unsaved_changes_modal;

  const {
    organizationIntegrations: connectedIntegrations,
    reqOrganizationIntegrations,
    isLoadingOrganizationIntegrations,
    isMutatingOrganizationIntegrations,
    revalidateOrganizationIntegrations,
  } = useOrganizationIntegrations({});

  const { mergeLinkToken, trigger, isLoadingLink, reset } = useMergeLinkToken();
  const { createMergeOrganizationIntegration } =
    useMergeOrganizationIntegration();

  const defaultFeatureStates =
    selectedIntegration?.features?.reduce<IntegrationFormFeatureField>(
      (acc, feature) => {
        if (feature.feature_name) {
          return {
            ...acc,
            [feature.feature_name]: feature.checked,
          };
        }
        return acc;
      },
      {},
    );
  const defaultMappingValue = selectedIntegration?.mappings?.reduce<
    Record<string, string>
  >((acc, mapping) => {
    if (mapping.value && mapping.key) {
      return { ...acc, [mapping.key]: mapping.value };
    }
    return acc;
  }, {});
  const defaultValues: IntegrationFormFields = {
    features: defaultFeatureStates,
    mappings: defaultMappingValue,
    apiToken: '',
    clientSecret: '',
  };
  const enabledSelectedOrganizationIntegration =
    getConnectedOrganizationIntegration(
      connectedIntegrations,
      selectedIntegration,
    );

  const enabledFeatures =
    enabledSelectedOrganizationIntegration?.features?.reduce<IntegrationFormFeatureField>(
      (acc, feature) => ({
        ...acc,
        [feature]: true,
      }),
      {},
    );

  const methods = useForm<IntegrationFormFields>({
    mode: 'onChange',
    defaultValues: defaultValues,
    values: {
      features: enabledFeatures ?? defaultFeatureStates,
      mappings:
        (enabledSelectedOrganizationIntegration?.mappings as Record<
          string,
          string
        >) ?? defaultMappingValue,
      clientSecret: enabledSelectedOrganizationIntegration?.clientSecret ?? '',
      apiToken: enabledSelectedOrganizationIntegration?.apiToken ?? '',
    },
  });

  const handleSetTokenOnApi = (publicToken: string) => {
    const { features = {}, mappings } = methods.getValues();
    const featuresArray = Object.entries(features).reduce<string[]>(
      (acc, [feature, enabled]) => {
        if (enabled) {
          return [...acc, feature];
        }
        return acc;
      },
      [],
    );

    createMergeOrganizationIntegration({
      integrationIdentifier: selectedIntegration?.integration_identifier,
      integrationName: selectedIntegration?.organization_integration_name,
      publicToken,
      features: featuresArray as IntegrationFeatureType[],
      mappings,
    });
    revalidateOrganizationIntegrations();
  };
  const handleMergeLinkError = (errors: ValidationErrors) => {
    console.log('Error during merge setup', { errors });
    const details = errors.map((error) => error.detail);
    onError({
      show: true,
      message: details.join(' | '),
      variant: 'error',
    });
  };

  const { open, isReady, error } = useMergeLink({
    linkToken: mergeLinkToken?.linkToken,
    onSuccess: handleSetTokenOnApi,
    onExit: () => {
      setSelectedIntegration((current) => ({
        ...current,
        open: true,
      }));
    },
    onValidationError: handleMergeLinkError,
  });

  const organizationPersonalDetails = organization?.personal_details;
  const profileFields = organizationPersonalDetails
    ? Object.entries(organizationPersonalDetails).reduce<string[]>(
        (fields, [profileField, settings]) => {
          if (settings.enabled) {
            return [...fields, profileField];
          }
          return fields;
        },
        [],
      )
    : [];
  const customFields = organization?.profile_fields ?? [];

  const { organizationIntegrations: sanityOrganizationIntegrations } =
    useSanityOrganizationIntegrations({ localeCode: 'en_GB' });

  const handleOpenModal = (integration: SelectedSanityIntegration) => {
    setSelectedIntegration(integration);
    if (
      integration.integration_identifier &&
      integration.organization_integration_name
    ) {
      trigger(
        {
          url: `/admin/organizationIntegrations/merge/linkToken`,
          method: 'PUT',
          params: {
            integrationIdentifier: integration.integration_identifier,
            integrationName: integration.organization_integration_name,
          },
        },
        {
          onError: (error) => {
            onError({
              show: true,
              message: error.message,
              variant: 'error',
            });
          },
        },
      );
    }
  };
  const handleCloseModal = () => {
    const { isDirty } = methods.formState;
    if (isDirty) {
      setIsDiscardChangesModalOpen(true);
    } else {
      setSelectedIntegration(undefined);
      reset();
    }
  };
  const handleEnable = async (
    data: IntegrationFormFields,
    integrationName?: OrganizationIntegrationName,
    integrationIdentifier?: string,
  ) => {
    const { apiToken, clientSecret, features = {}, mappings } = data;
    const featuresArray = Object.entries(features).reduce<string[]>(
      (acc, [feature, enabled]) => {
        if (enabled) {
          return [...acc, feature];
        }
        return acc;
      },
      [],
    );
    const apiTokenFiltered = apiToken?.startsWith('***') ? undefined : apiToken;
    const clientSecretFiltered = clientSecret?.startsWith('***')
      ? undefined
      : clientSecret;
    reqOrganizationIntegrations(
      {
        method: 'PUT',
        url: `/admin/organizationIntegrations/${integrationName}`,
        data: {
          integrationName,
          integrationIdentifier,
          apiToken: apiTokenFiltered,
          clientSecret: clientSecretFiltered,
          features: featuresArray as IntegrationFeatureType[],
          mappings,
        },
      },
      {
        onError: (error) => {
          onError({
            show: true,
            message:
              error.message ??
              `Unknown error adding ${integrationName} integration`,
            variant: 'error',
          });
        },
      },
    );
  };

  const handleDisconnect = (integrationName?: OrganizationIntegrationName) => {
    reqOrganizationIntegrations(
      {
        method: 'DELETE',
        url: `/admin/organizationIntegrations/${integrationName}`,
      },
      {
        onError: (error) => {
          onError({
            show: true,
            message:
              error.message ??
              `Unknown error deleting ${integrationName} integration`,
            variant: 'error',
          });
        },
      },
    );
  };

  const handleContinueEditing = () => {
    setIsDiscardChangesModalOpen(false);
  };
  const handleDiscard = () => {
    setSelectedIntegration(undefined);
    reset();
    methods.reset();
  };

  useEffect(() => {
    if (error) {
      console.error('Error during merge setup', { error });
      onError({
        show: true,
        message: error.message,
        variant: 'error',
      });
    }
  }, [error, onError]);

  const actions: ButtonProps[] = [
    {
      label: discard_unsaved_changes_modal?.discard_changes_button_label,
      variant: 'contained',
      color: 'error',
      action: handleDiscard,
    },
    {
      label: discard_unsaved_changes_modal?.continue_editing_button_label,
      variant: 'contained',
      color: 'info',
      action: handleContinueEditing,
    },
  ];

  return (
    <FormProvider {...methods}>
      <ConfirmationModal
        open={isDiscardChangesModalOpen}
        title={discard_unsaved_changes_modal?.title}
        description={discard_unsaved_changes_modal?.description}
        buttons={actions}
        onClose={handleContinueEditing}
      />
      <IntegrationView
        enabledOrganizations={enabledOrganizations}
        sanityOrganizationIntegrations={sanityOrganizationIntegrations}
        connectedIntegrations={connectedIntegrations}
        selectedSanityIntegration={selectedIntegration}
        onOpenModal={handleOpenModal}
        onCloseModal={handleCloseModal}
        customFields={customFields}
        profileFields={profileFields}
        onClickEnable={handleEnable}
        onClickDisconnect={handleDisconnect}
        isLoading={
          isLoadingOrganizationIntegrations ||
          isMutatingOrganizationIntegrations
        }
        onMergeSetupClick={open}
        isLoadingMergeLink={!isReady || isLoadingLink}
      />
    </FormProvider>
  );
}
