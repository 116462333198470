import { SanityProfileField } from '@guider-global/shared-types';
import {
  AlertBar,
  Avatar,
  ButtonStack,
  ButtonStackItem,
  Chip,
  CustomModal,
  FormTextField,
  IconButton,
  JSONSchema,
  Stack,
  TextStack,
} from '@guider-global/ui';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Close from '@mui/icons-material/Close';
import { useMediaQuery, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { IntegrationFeaturesRow } from '../IntegrationFeaturesRow';
import { IntegrationMappings } from '../IntegrationMappings';
import { SelectedSanityIntegration } from '../IntegrationView';
import { MergeLinkButton } from '../Merge';

export interface IntegrationModalProps {
  selectedSanityIntegration: Partial<SelectedSanityIntegration> | undefined;
  onClose: () => void;
  onClickEnable: () => void;
  onClickDisconnect: () => void;
  onMergeSetupClick: () => void;
  isConnected?: boolean;
  isLoading?: boolean;
  isUpdateDisabled?: boolean;
  updateActionLabel?: string;
  disconnectActionLabel?: string;
  primaryActionLabel?: string;
  disabled?: boolean;
  customFields: SanityProfileField[];
  profileFields: string[];
  isLoadingMergeLink?: boolean;
}

export function IntegrationModal({
  selectedSanityIntegration,
  onClose,
  onClickEnable,
  onClickDisconnect,
  onMergeSetupClick,
  isConnected,
  isLoading,
  isUpdateDisabled,
  updateActionLabel = 'Update',
  disconnectActionLabel = 'Disconnect',
  primaryActionLabel = 'Enable integration',
  disabled,
  customFields,
  profileFields,
  isLoadingMergeLink,
}: IntegrationModalProps) {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const {
    control,
    formState: { isValid, isDirty },
  } = useFormContext();

  const buttons: ButtonStackItem[] = isConnected
    ? [
        {
          key: '1',
          variant: 'outlined',
          label: updateActionLabel,
          color: 'secondary',
          onClick: onClickEnable,
          disabled: isUpdateDisabled || disabled || !isValid || !isDirty,
          loading: isLoading,
        },
        {
          key: '2',
          variant: 'outlined',
          label: disconnectActionLabel,
          color: 'error',
          onClick: onClickDisconnect,
          disabled: disabled,
          loading: isLoading,
        },
      ]
    : [
        {
          key: '2',
          variant: 'contained',
          label: primaryActionLabel,
          color: 'primary',
          onClick: onClickEnable,
          disabled: disabled || !isValid || !isDirty,
          loading: isLoading,
        },
      ];
  const customFieldsSchema = customFields.reduce<JSONSchema['properties']>(
    (schema, field) => {
      return {
        ...schema,
        [field.id.current]: {
          type: 'string',
          examples: selectedSanityIntegration?.integration_fields,
        },
      };
    },
    {},
  );
  const mappingSchema = profileFields.reduce<JSONSchema['properties']>(
    (schema, mapping) => {
      if (mapping) {
        return {
          ...schema,
          [mapping]: {
            type: 'string',
            examples: [
              ...(selectedSanityIntegration?.integration_fields ?? []),
            ],
          },
        };
      }
      return schema;
    },
    {},
  );
  const schema: JSONSchema = {
    title: `${selectedSanityIntegration?.heading} Schema`,
    type: 'object',
    properties: {
      ...mappingSchema,
      ...customFieldsSchema,
    },
  };
  const apiToken = selectedSanityIntegration?.input_fields?.api_token;
  const clientSecret = selectedSanityIntegration?.input_fields?.client_secret;
  return (
    <CustomModal
      isOpen={selectedSanityIntegration?.open ?? false}
      onClose={onClose}
      fullScreen={isMobile}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: { sx: 0, md: '16px' },
        },
      }}
    >
      <Stack direction="column" p={2} spacing={2}>
        {isConnected && (
          <Chip
            label="Connected"
            color="success"
            size="small"
            icon={<CheckCircle style={{ color: 'white' }} />}
          />
        )}
        <Stack
          direction={'column'}
          spacing={isMobile ? 0 : 1.5}
          flexWrap={'nowrap'}
        >
          <Stack
            direction={'row'}
            spacing={isMobile ? 0 : 1.5}
            flexWrap={'nowrap'}
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Avatar size="medium" src={selectedSanityIntegration?.image} />

            <IconButton
              variant="regular"
              onClick={() => onClose()}
              icon={<Close />}
            />
          </Stack>

          <TextStack
            heading={selectedSanityIntegration?.heading}
            subtitles={[
              {
                text: selectedSanityIntegration?.description,
                color: palette.paper.text.secondary,
                fontWeight: '400',
                lineHeight: '175%',
              },
            ]}
            size="large"
            sx={{
              color: palette.paper.text.primary,
              width: isMobile ? '100%' : '70%',
              justifySelf: isMobile ? 'flex-start' : '',
              pt: isMobile ? '8px' : '',
              order: isMobile ? 3 : '',
            }}
          />
        </Stack>
        <Stack direction={'column'} pt={1} spacing={2}>
          {selectedSanityIntegration?.document_instructions?.file && (
            <AlertBar
              text={{
                text: selectedSanityIntegration?.document_instructions?.heading,
                variant: 'h6',
                color: palette.paper.text.primary,
              }}
              title={selectedSanityIntegration?.document_instructions?.heading}
              actionLabel={
                selectedSanityIntegration?.document_instructions?.action_label
              }
              action={() => {
                const pdfUrl =
                  selectedSanityIntegration?.document_instructions?.file;
                window.open(pdfUrl, '_blank');
              }}
              sx={{
                backgroundColor: '#EBF6FF',
              }}
            />
          )}
          {apiToken?.enabled && (
            <FormTextField
              label={apiToken?.label}
              placeholder={apiToken?.placeholder}
              required={apiToken?.required}
              control={control}
              name={'apiToken'}
            />
          )}
          {clientSecret?.enabled && (
            <FormTextField
              label={clientSecret?.label}
              placeholder={clientSecret?.placeholder}
              required={clientSecret?.required}
              control={control}
              name={'clientSecret'}
            />
          )}
          <MergeLinkButton
            isConnected={isConnected}
            enableLabel={selectedSanityIntegration?.merge_link?.enable_label}
            connectedLabel={
              selectedSanityIntegration?.merge_link?.connected_label
            }
            show={selectedSanityIntegration?.organization_integration_name?.startsWith(
              'merge-',
            )}
            onClick={onMergeSetupClick}
            isLoading={isLoadingMergeLink}
          />
          <IntegrationMappings
            schema={schema}
            id={
              selectedSanityIntegration?.integration_identifier ??
              selectedSanityIntegration?.organization_integration_name
            }
          />
          <IntegrationFeaturesRow
            features={selectedSanityIntegration?.features}
          />
        </Stack>
        <ButtonStack
          direction={isMobile ? 'column' : 'row'}
          justifyContent={'flex-end'}
          buttons={buttons}
          sx={{ width: '100%', mt: '24px' }}
          buttonsWidth={!isMobile ? '33%' : 'inherit'}
        />
      </Stack>
    </CustomModal>
  );
}
