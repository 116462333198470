import { datadogLogs } from '@guider-global/datadog';
import { GuiderSWRConfig } from '@guider-global/swr';
import { NoticeSnackbar, ThemeWrapper, theme } from '@guider-global/ui';
import { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { clearSnackbar, setSnackbar } from 'store/slices';

export interface SnackbarWrapperProps extends PropsWithChildren {}

export function SnackbarWrapper({ children }: SnackbarWrapperProps) {
  const dispatch = useDispatch();
  const snackbarState = useSelector((state: RootState) => state.snackbarSlice);

  const handleLogger = ({
    message,
    status,
    name,
  }: {
    name: string;
    message: string;
    status?: number;
  }) => {
    console.error(`message:${message} | status:${status} | name:${name}`);
    if (datadogLogs.logger.error) {
      datadogLogs.logger.error(
        `message:${message} | status:${status} | name:${name}`,
      );
    }
  };

  return (
    <ThemeWrapper customTheme={theme.appTheme}>
      <GuiderSWRConfig
        value={{
          onError: (error, key, config) => {
            handleLogger({
              message: error.message,
              status: error.status,
              name: error.name,
            });
            dispatch(
              setSnackbar({
                show: true,
                message: error.message,
                variant: 'error',
              }),
            );
          },
        }}
      >
        {children}
        {snackbarState.show && (
          <NoticeSnackbar
            {...snackbarState}
            onClose={() => dispatch(clearSnackbar(true))}
          />
        )}
      </GuiderSWRConfig>
    </ThemeWrapper>
  );
}
