import { OrganizationIntegrations as SanityOrganizationIntegrations } from '@guider-global/sanity-types';
import {
  IntegrationFeatureType,
  OrganizationIntegration,
  OrganizationIntegrationName,
  SanityProfileField,
} from '@guider-global/shared-types';
import { Grid } from '@mui/material';
import { IntegrationCard, IntegrationModal } from '../index';
import { useFormContext } from 'react-hook-form';

export type IntegrationFormFeatureField = {
  [featureName in IntegrationFeatureType]?: boolean;
};

export interface IntegrationFormFields {
  features?: IntegrationFormFeatureField;
  mappings?: Record<string, string>;
  apiToken?: string;
  clientSecret?: string;
}

export interface SelectedSanityIntegration
  extends SanityOrganizationIntegrations {
  open: boolean;
}

export interface IntegrationViewProps {
  sanityOrganizationIntegrations: SanityOrganizationIntegrations[] | undefined;
  enabledOrganizations: Partial<SanityOrganizationIntegrations>[] | undefined;
  connectedIntegrations: OrganizationIntegration[] | undefined;
  selectedSanityIntegration: Partial<SelectedSanityIntegration> | undefined;
  customFields: SanityProfileField[];
  profileFields: string[];
  isLoading?: boolean;
  isLoadingMergeLink?: boolean;
  onOpenModal: (integration: SelectedSanityIntegration) => void;
  onCloseModal: () => void;
  onClickEnable: (
    data: IntegrationFormFields,
    integrationName?: OrganizationIntegrationName,
    integrationIdentifier?: string,
  ) => void;
  onClickDisconnect: (integrationName?: OrganizationIntegrationName) => void;
  onMergeSetupClick: () => void;
}

export function IntegrationView({
  sanityOrganizationIntegrations,
  enabledOrganizations,
  connectedIntegrations = [],
  selectedSanityIntegration,
  customFields,
  profileFields,
  isLoading,
  isLoadingMergeLink,
  onOpenModal,
  onCloseModal,
  onClickEnable,
  onClickDisconnect,
  onMergeSetupClick,
}: IntegrationViewProps) {
  const enabledSelectedOrganizationIntegration =
    getConnectedOrganizationIntegration(
      connectedIntegrations,
      selectedSanityIntegration,
    );
  const isSelectedOrganizationIntegrationEnabled = Boolean(
    enabledSelectedOrganizationIntegration,
  );

  const { handleSubmit } = useFormContext<IntegrationFormFields>();

  return (
    <>
      <Grid container spacing={3} justifyContent={{ xs: 'center', md: 'left' }}>
        {sanityOrganizationIntegrations?.map((sanityIntegration) => {
          const connectedOrganizationIntegration =
            getConnectedOrganizationIntegration(
              connectedIntegrations,
              sanityIntegration,
            );
          const isIntegrationConnected = Boolean(
            connectedOrganizationIntegration,
          );
          const isOrganizationEnabled = getEnabledSanityIntegration(
            enabledOrganizations,
            sanityIntegration,
          );
          return (
            <Grid item key={sanityIntegration._id}>
              <IntegrationCard
                heading={sanityIntegration.heading}
                text={sanityIntegration.description}
                avatarSrc={sanityIntegration.image}
                isConnected={isIntegrationConnected}
                isLoading={isLoading}
                onClick={() => {
                  onOpenModal({ ...sanityIntegration, open: true });
                }}
                isDisabled={!isOrganizationEnabled}
              />
            </Grid>
          );
        })}
      </Grid>
      <IntegrationModal
        selectedSanityIntegration={selectedSanityIntegration}
        onClose={onCloseModal}
        onClickEnable={handleSubmit((data) =>
          onClickEnable(
            data,
            selectedSanityIntegration?.organization_integration_name,
            selectedSanityIntegration?.integration_identifier,
          ),
        )}
        onClickDisconnect={() =>
          onClickDisconnect(
            selectedSanityIntegration?.organization_integration_name,
          )
        }
        isLoading={isLoading}
        isLoadingMergeLink={isLoadingMergeLink}
        onMergeSetupClick={onMergeSetupClick}
        customFields={customFields}
        profileFields={profileFields}
        isConnected={isSelectedOrganizationIntegrationEnabled}
      />
    </>
  );
}

export const getConnectedOrganizationIntegration = (
  connectedIntegrations: OrganizationIntegration[] | undefined,
  sanityIntegration: Partial<SanityOrganizationIntegrations> | undefined,
): OrganizationIntegration | undefined =>
  connectedIntegrations?.find((connectedIntegration) => {
    if (
      sanityIntegration?.organization_integration_name?.startsWith('merge-')
    ) {
      return (
        connectedIntegration.integrationIdentifier ===
        sanityIntegration.integration_identifier
      );
    } else {
      return (
        connectedIntegration.integrationName ===
        sanityIntegration?.organization_integration_name
      );
    }
  });

export const getEnabledSanityIntegration = (
  enabledIntegrations: Partial<SanityOrganizationIntegrations>[] | undefined,
  sanityIntegration: Partial<SanityOrganizationIntegrations> | undefined,
): boolean | undefined =>
  enabledIntegrations?.some((enabledIntegration) => {
    if (
      sanityIntegration?.organization_integration_name?.startsWith('merge-')
    ) {
      return (
        enabledIntegration.integration_identifier ===
        sanityIntegration.integration_identifier
      );
    } else {
      return (
        enabledIntegration.organization_integration_name ===
        sanityIntegration?.organization_integration_name
      );
    }
  });
