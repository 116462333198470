import { IntegrationFeatureType } from '@guider-global/shared-types';
import {
  Checkbox,
  CheckboxProps,
  Divider,
  Stack,
  Text,
} from '@guider-global/ui';
import { useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  IntegrationFormFields,
  SelectedSanityIntegration,
} from '../IntegrationView';

export interface IntegrationFeatureRowProps {
  features: SelectedSanityIntegration['features'] | undefined;
  featuresHeading?: string;
}

export function IntegrationFeaturesRow({
  features,
  featuresHeading,
}: IntegrationFeatureRowProps) {
  const { palette } = useTheme();

  return (
    <Stack direction="column">
      <Divider width={'100%'} />
      <Text
        text={featuresHeading}
        mt={'12px'}
        variant="h6"
        color={palette.paper.text.primary}
      />
      {features?.map((feature) => {
        return (
          <IntegrationFeature feature={feature} key={feature.feature_name} />
        );
      })}
      <Divider width={'100%'} />
    </Stack>
  );
}

export interface Feature {
  heading?: string;
  subtitle?: string;
  feature_name?: IntegrationFeatureType;
  checkboxProps?: Pick<CheckboxProps, 'style' | 'isNotStyled' | 'variant'>;
}
export interface IntegrationFeatureProps {
  feature: Feature;
}
function IntegrationFeature({ feature }: IntegrationFeatureProps) {
  const { control } = useFormContext<IntegrationFormFields>();
  if (!feature.feature_name) return null;
  const toBoolean = (value?: string | boolean) => {
    if (typeof value === 'string') {
      if (value === 'true') return true;
      return false;
    }
    if (typeof value === 'boolean') {
      return value;
    }
    return false;
  };
  return (
    <Controller
      name={`features.${feature.feature_name}`}
      control={control}
      render={({ field: { onChange, value } }) => {
        const validatedValue = toBoolean(value);
        return (
          <Checkbox
            heading={feature.heading}
            subtitle={feature.subtitle}
            onChange={onChange}
            isChecked={validatedValue}
            data-cy={feature.feature_name}
          />
        );
      }}
    />
  );
}
