import { NoticeSnackbar, NoticeSnackbarProps } from '@guider-global/ui';
import { Box } from '@mui/material';
import { IntegrationsContainer } from 'containers';
import { useState } from 'react';
import { PageView } from 'views';

export function IntegrationsPage() {
  const [snackbarProps, setSnackbarProps] = useState<NoticeSnackbarProps>({
    show: false,
    message: '',
    variant: 'error',
  });

  return (
    <PageView
      title={'Integrations'}
      subtitles={['Manage all of your integrations in one place.']}
    >
      <NoticeSnackbar {...snackbarProps} />
      <Box minHeight="150vh" width="100%">
        <IntegrationsContainer onError={(error) => setSnackbarProps(error)} />
      </Box>
    </PageView>
  );
}
