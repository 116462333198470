import { Button } from '@guider-global/ui';

export interface MergeLinkButtonProps {
  enableLabel?: string;
  connectedLabel?: string;
  show?: boolean;
  isConnected?: boolean;
  onClick: () => void;
  isLoading?: boolean;
}
export function MergeLinkButton({
  enableLabel,
  connectedLabel,
  show,
  isConnected,
  onClick,
  isLoading,
}: MergeLinkButtonProps) {
  if (!show) return null;
  return (
    <Button
      label={isConnected ? connectedLabel : enableLabel}
      onClick={onClick}
      loading={isLoading}
    />
  );
}
