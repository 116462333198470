import { Box } from '@mui/material';
import { ActionCard, Chip } from '@guider-global/ui';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Settings from '@mui/icons-material/Settings';

export interface IntegrationCardProps {
  avatarSrc?: string;
  heading: string | undefined;
  text?: string;
  isConnected?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  beta?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export function IntegrationCard({
  avatarSrc,
  heading,
  text,
  isConnected,
  onClick,
  beta = false,
  isLoading,
  isDisabled,
}: IntegrationCardProps) {
  return (
    <Box sx={{ width: '286px', minHeight: '100%', height: '100%' }}>
      <ActionCard
        fullHeight
        textChip={
          beta
            ? [
                {
                  position: 'right',
                  variant: 'outlined',
                  label: 'Beta',
                  bgColor: 'white',
                },
              ]
            : undefined
        }
        heading={heading}
        avatarHasBorder={false}
        chip={
          isConnected && (
            <Chip
              label="Connected"
              color="success"
              size="small"
              icon={<CheckCircle style={{ color: 'white' }} />}
            />
          )
        }
        text={text}
        avatarSrc={avatarSrc}
        buttons={[
          {
            key: '1',
            label: isConnected ? 'Manage Integration' : 'Connect',
            variant: isConnected ? 'outlined' : 'contained',
            onClick: onClick,
            startIcon: isConnected && <Settings />,
            loading: isLoading,
            disabled: isDisabled,
          },
        ]}
      />
    </Box>
  );
}
