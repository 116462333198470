import {
  IMergeHRISLinkToken,
  OrganizationIntegration,
} from '@guider-global/shared-types';
import {
  AxiosPut,
  AxiosRequestTypes,
  IUseGuiderSWRMutationResult,
  useGuiderSWRMutation,
} from '@guider-global/swr';
export type IMergeLinkToken = IMergeHRISLinkToken;

// TO BE extended with other link token types
type MergeLinkRequests = AxiosPut<
  IMergeLinkToken,
  never,
  {
    integrationName: OrganizationIntegration['integrationName'];
    integrationIdentifier: string | undefined;
  }
>;

export interface IMergeLinkTokenResponse<Requests extends AxiosRequestTypes>
  extends Omit<
    IUseGuiderSWRMutationResult<Requests>,
    'response' | 'isMutating'
  > {
  mergeLinkToken: IMergeLinkToken | undefined;
  isLoadingLink: boolean;
}

export function useMergeLinkToken(): IMergeLinkTokenResponse<MergeLinkRequests> {
  const { response, trigger, error, isMutating, reset } =
    useGuiderSWRMutation<MergeLinkRequests>({
      url: `/admin/organizationIntegrations/merge/linkToken`,
    });

  return {
    mergeLinkToken: response?.at(0),
    trigger,
    error,
    isLoadingLink: isMutating,
    reset,
  };
}
